import React from 'react'
import styled from 'styled-components'

import MaskedImage from '../masked-image'

const TwoImages = ({ imageLeft, imageRight }) => (
  <Outer>
    <ImageWrapper>
      <Image>
        <MaskedImage
          fluid={imageLeft.imageFile.childImageSharp.fluid}
          alt={imageRight.imageFile.alt}
        />
      </Image>
      <Caption dangerouslySetInnerHTML={{ __html: imageLeft.caption }} />
    </ImageWrapper>
    <ImageWrapper>
      <Image>
        <MaskedImage
          fluid={imageRight.imageFile.childImageSharp.fluid}
          alt={imageRight.imageFile.alt}
        />
      </Image>
      <Caption dangerouslySetInnerHTML={{ __html: imageRight.caption }} />
    </ImageWrapper>
  </Outer>
)

export default TwoImages

const Outer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 17rem 16.5% 0;

  @media (max-width: 1260px) {
    padding: 10vw 12.5%;
  }

  @media (max-width: 650px) {
    padding: 65px 0;
  }
`

const ImageWrapper = styled.div`
  width: 45%;

  &:nth-child(even) {
    margin-top: 12%;
  }

  @media (max-width: 650px) {
    width: 48%;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 150%;
  }
`

const Caption = styled.aside`
  p {
    display: block;
    color: #000;
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 2;
    margin: 1rem 0 0;
    opacity: 0.3;
  }

  @media (max-width: 650px) {
    display: none;
  }
`
