import React from 'react'
import styled from 'styled-components'
import Parallax from '../parallax'

import MaskedkImage from '../masked-image'

const FullWidthImage = ({ text, image }) => (
  <Outer>
    <ParallaxTitle>
      <Parallax speed={6}>
        <Title>{text}</Title>
      </Parallax>
    </ParallaxTitle>
    <Image>
      <MaskedkImage
        fluid={image.imageFile.childImageSharp.fluid}
        alt={image.imageFile.alt}
        position="relative"
      />
    </Image>
  </Outer>
)

export default FullWidthImage

const Outer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 10vw;
  z-index: 5;

  @media (max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }
`

const ParallaxTitle = styled.div`
  z-index: 10;
`

const Title = styled.p`
  position: relative;
  width: 100%;
  max-width: 900px;
  font-size: 9.5rem;
  font-family: ${props => props.theme.fonts.butler};
  color: ${props => props.theme.colours.orange};
  line-height: 1.2;
  transform: rotate(-90deg);
  z-index: 5;

  @media (max-width: 1700px) {
    max-width: 720px;
  }

  @media (max-width: 1000px) {
    font-size: 7.5rem;
    max-width: 470px;
  }

  @media (max-width: 650px) {
    font-size: 6.5rem;
    max-width: 360px;
    transform: rotate(-90deg) translateY(110%);
    transform-origin: bottom left;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
`
