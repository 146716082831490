import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { animated, useSpring, config } from 'react-spring'
import Img from 'gatsby-image'
import { TransitionContext } from '../context/transition-context'

const Project = ({ index, project }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const imageAnimation = useSpring({
    transform: `translateX(0rem)`,
    opacity: 1,
    from: {
      transform: `translateX(3rem)`,
      opacity: 0
    },
    config: config.molasses,
    delay: index * 300 + 100
  })

  const textAnimation = useSpring({
    transform: `translateY(0rem) rotate(0deg)`,
    opacity: 1,
    from: {
      transform: `translateY(3rem) rotate(5deg)`,
      opacity: 0
    },
    config: config.slow,
    delay: index * 300 + 100
  })

  return (
    <ProjectLink
      to={`/projects/${project.slug}`}
      onClick={event => {
        event.preventDefault()
        setTransitionActive(true)
        setTimeout(() => {
          navigate(`/projects/${project.slug}`)
          setTransitionActive(false)
        }, 500)
      }}
    >
      <SlideText style={textAnimation}>
        <p>{`${index < 10 ? '0' : ''}${index + 1}`}</p>
        <h2>
          {project.title}
          <br />
          {project.projectFields.hero.address}
        </h2>
      </SlideText>
      <ImageContainer style={imageAnimation}>
        <Image
          fluid={project.projectFields.hero.image.imageFile.childImageSharp.fluid}
          alt={project.projectFields.hero.image.altText}
          style={{ position: 'absolute' }}
        />
      </ImageContainer>
    </ProjectLink>
  )
}

export default Project

const ProjectLink = styled(Link)`
  display: block;

  &::before {
    content: '';
    display: block;
    padding-bottom: 115%;

    @media (max-width: 850px) {
      display: none;
    }
  }
`

const ImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 3.6rem);
  height: 100%;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 850px) {
    width: 100%;
  }
`

const Image = styled(Img)`
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  transition: opacity 1s ease, transform 1s ease;
  z-index: 1;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3;
    z-index: 2;
  }
`

const SlideText = styled(animated.div)`
  position: absolute;
  bottom: 4rem;
  left: 0;
  z-index: 5;

  @media (max-width: 850px) {
    bottom: 1.5rem;
    left: 2.5rem;
  }

  h2 {
    color: var(--primary);
    font-size: 4.5rem;
    line-height: 1.2;
    margin: 0;
    white-space: pre-wrap;
    max-width: 14ch;
    transition: color 1s;

    @media (max-width: 850px) {
      font-family: ${props => props.theme.fonts.maison};
      font-weight: 400;
      font-size: 1.8rem;
      text-transform: uppercase;
      letter-spacing: 0.4rem;
      line-height: 1.4;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 1.4;
    opacity: 0.5;
    color: var(--primary);
    transition: color 1s;

    @media (max-width: 850px) {
      display: none;
    }
  }
`
