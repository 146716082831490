import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import { Swiper, Keyboard } from 'swiper/js/swiper.esm'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'

import Project from './mini-portfolio-project'
import Title from '../title'
import BackgroundColor from './background-color'

import 'swiper/css/swiper.css'

const MiniPortfolio = ({
  projects,
  categories,
  projectCategory,
  currentProject,
  commercialOffices,
  communitySpaces,
  historicRenovations,
  multifamilyResidential,
  restaurantsAndRental
}) => {
  const [smallFiltersToggle, setsmallFiltersToggle] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(projectCategory)
  const [projectsToList, setProjectsToList] = useState(projects)
  const [swiper, updateSwiper] = useState(null)
  const [render, setRender] = useState(0)

  const filterProjects = category => {
    switch (category) {
      case 'Multifamily Residential':
        setProjectsToList(multifamilyResidential)
        break
      case 'Historic Renovations':
        setProjectsToList(historicRenovations)
        break
      case 'Commercial Offices':
        setProjectsToList(commercialOffices)
        break
      case 'Restaurants and Retail':
        setProjectsToList(restaurantsAndRental)
        break
      case 'Community Spaces':
        setProjectsToList(communitySpaces)
        break
      default:
    }
  }

  useEffect(() => {
    // If a user landed at this page from a previous category,
    // use that to determine which category should be shown.
    // This helps show the most relivant category if the project
    // belong to multiple categories
    if (sessionStorage.getItem('category')) {
      setCurrentCategory(sessionStorage.getItem('category'))
      filterProjects(sessionStorage.getItem('category'))
    } else {
      filterProjects(currentCategory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const projectsWrapperAnimation = useSpring({
    opacity: smallFiltersToggle ? 0 : 1,
    from: {
      opacity: 1
    },
    config: config.slow
  })

  const handleSmallFilterClick = category => {
    setsmallFiltersToggle(true)

    setTimeout(() => {
      setCurrentCategory(category)
      setsmallFiltersToggle(false)

      // If the categry is all, use the unfiltered list of project
      // Else, run the filtering functiona and pass the cateogry name
      if (category === 'All') {
        setProjectsToList(projects)
      } else {
        filterProjects(category)
      }

      setRender(render + 1)

      // The animations mess with the swiper layout spacing so triggering an update
      // just after the animation is happening fixes this
      swiper.update()
    }, 1000)
  }

  const swiperParams = {
    Swiper,
    modules: [Keyboard],
    keyboard: {
      enabled: true,
      onlyInViewport: false
    },
    mousewheel: true,
    releaseOnEdges: false,
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 1000,
    threshold: 20,
    grabCursor: true,
    centeredSlides: true,
    breakpoints: {
      850: {
        centeredSlides: false,
        spaceBetween: 40
      }
    }
  }

  // Create an array of projects without the current project
  // const projectsWithoutCurrent = projectsToList.filter(project =>
  //   project.node === null
  //     ? project.node.projectId !== currentProject
  //     : project.projectId !== currentProject
  // )

  return (
    <Outer>
      <BackgroundColor />
      <TitleContainer style={projectsWrapperAnimation}>
        <Title text={currentCategory} type="h3" />
      </TitleContainer>
      <ProjectsContainer>
        <ProjectsFilterWrapper style={projectsWrapperAnimation}>
          <ReactIdSwiperCustom {...swiperParams} getSwiper={updateSwiper}>
            {projectsToList.map((project, index) => (
              <div key={index + render}>
                <Project
                  index={index}
                  project={typeof project.node !== 'undefined' ? project.node : project}
                />
              </div>
            ))}
          </ReactIdSwiperCustom>
        </ProjectsFilterWrapper>
      </ProjectsContainer>
      <SmallFilters>
        <button
          type="button"
          onClick={() => handleSmallFilterClick('All')}
          className={currentCategory === 'All' ? 'active' : ''}
        >
          All
        </button>
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            {categories[index].node.name !== 'Uncategorized' && (
              <button
                key={index}
                type="button"
                className={category.node.name === currentCategory ? 'active' : ''}
                onClick={() => handleSmallFilterClick(categories[index].node.name)}
              >
                {category.node.name}
              </button>
            )}
          </React.Fragment>
        ))}
      </SmallFilters>
      <MobileFilters onChange={e => handleSmallFilterClick(e.target.value)}>
        <option value="All" label="View all">
          View all
        </option>
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            {categories[index].node.name !== 'Uncategorized' && (
              <option value={categories[index].node.name} label={categories[index].node.name}>
                {categories[index].node.name}
              </option>
            )}
          </React.Fragment>
        ))}
      </MobileFilters>
    </Outer>
  )
}

export default MiniPortfolio

const Outer = styled.section`
  position: relative;
  width: 100%;
  padding: 5vw 0;
`

const TitleContainer = styled(animated.aside)`
  position: absolute;
  top: 8%;
  left: 6.5%;
  pointer-events: none;
  max-width: 55rem;
  z-index: 10;

  h3 {
    color: var(--primary);
    font-size: 7rem;
    line-height: 1.2;
    margin-bottom: 0;
    transition: color 1s;
    letter-spacing: -0.1rem;
  }
`

const ProjectsContainer = styled(animated.section)`
  position: relative;
  width: 85%;
  height: 90%;
  margin-left: 15%;

  @media (max-width: 850px) {
    width: 100%;
    margin-left: 0;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    width: 38%;

    @media (max-width: 850px) {
      width: 80%;

      &::before {
        content: '';
        display: block;
        margin-bottom: 120%;
      }
    }
  }
`

const ProjectsFilterWrapper = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;
`

const SmallFilters = styled(animated.nav)`
  width: 100%;
  padding: 0 3rem;
  margin: 5vw auto 0;
  text-align: center;

  @media (max-width: 850px) {
    display: none;
  }

  button {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.4;
    color: #6c6c6c;
    background: none;
    border: none;
    margin: 0 1rem;
    padding: 0;
    transition: color 1s ease;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #fff;
    }

    &.active {
      color: ${props => props.theme.colours.orange};
      border-bottom: 1px solid ${props => props.theme.colours.orange};
    }
  }
`

const MobileFilters = styled(animated.select)`
  display: none;
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  font-size: 1.8rem;
  line-height: 1.6;
  text-align-last: center;
  color: #fff;
  line-height: 1.3;
  padding: 0.6em 1.4em 1em 0.8em;
  width: 80%;
  max-width: 40rem;
  box-sizing: border-box;
  margin: 3rem auto;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colours.orange};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTAgOSI+CiAgPHBhdGggaWQ9IlBvbHlnb25fMSIgZGF0YS1uYW1lPSJQb2x5Z29uIDEiIGQ9Ik01LDBsNSw5SDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMCA5KSByb3RhdGUoMTgwKSIgZmlsbD0iI2Q1NjUxYiIvPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right 1.6em top 35%, 0 0;
  background-size: 0.8em auto, 100%;

  @media (max-width: 850px) {
    display: block;
  }

  @media (max-width: 400px) {
    width: 100%;
    max-width: 100%;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`
