import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/single-project/hero.js'
import Intro from '../components/single-project/intro'
import MainContent from '../components/single-project/main-content'
import MiniPortfolio from '../components/single-project/mini-portfolio'

const SingleProject = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.projectBy.seo} />
    <Hero
      categories={data.wordPress.projectBy.categories.edges}
      title={pageContext.title}
      {...data.wordPress.projectBy.projectFields.hero}
    />
    <Intro {...data.wordPress.projectBy.projectFields.intro} />
    <MainContent {...data.wordPress.projectBy.projectFields.mainContent} />
    <MiniPortfolio
      projectCategory={data.wordPress.projectBy.categories.edges[0].node.name}
      categories={data.wordPress.categories.edges}
      projects={data.wordPress.projects.edges}
      currentProject={pageContext.wordpressId}
      commercialOffices={data.wordPress.pageBy.projectTemplateFields.commercialOffices}
      communitySpaces={data.wordPress.pageBy.projectTemplateFields.communitySpaces}
      historicRenovations={data.wordPress.pageBy.projectTemplateFields.historicRenovations}
      multifamilyResidential={data.wordPress.pageBy.projectTemplateFields.multifamilyResidential}
      restaurantsAndRental={data.wordPress.pageBy.projectTemplateFields.restaurantsAndRental}
    />
  </Layout>
)

export default SingleProject

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      ...PortfolioCategoryFragment
      ...PortfolioAllProjectsFragment
      pageBy(pageId: 99) {
        ...PortfolioFragment
      }
      projectBy(projectId: $wordpressId) {
        ...SeoFragmentSingleProject
        categories {
          edges {
            node {
              name
            }
          }
        }
        projectFields {
          ...ProjectHeroFragment
          ...ProjectIntroFragment
          ...ProjectMainContentFragment
        }
      }
    }
  }
`
