import React from 'react'
import styled from 'styled-components'

import MaskedkImage from '../masked-image'

const MiddleImages = ({ imageLeft, imageRight }) => (
  <Outer>
    <ImageWrapper>
      <Image>
        <MaskedkImage fluid={imageLeft.imageFile.childImageSharp.fluid} alt={imageRight.imageFile.alt} position="absolute" />
      </Image>
      <Caption dangerouslySetInnerHTML={{ __html: imageLeft.caption }} />
    </ImageWrapper>
    <ImageWrapper>
      <Image>
        <MaskedkImage fluid={imageRight.imageFile.childImageSharp.fluid} alt={imageRight.imageFile.alt} position="absolute" />
      </Image>
      <Caption dangerouslySetInnerHTML={{ __html: imageRight.caption }} />
    </ImageWrapper>
  </Outer>
)

export default MiddleImages

const Outer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0 5rem;

  @media (max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  width: 50%;

  &:nth-child(even) {
    margin-top: 26%;

    @media (max-width: 650px) {
      margin: 10px 0 0 auto;
    }
  }
  @media (max-width: 650px) {
    width: 70%;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 82%;
  }
`

const Caption = styled.aside`
  p {
    display: block;
    color: #000;
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    line-height: 2;
    margin: 1rem 0 0;
    opacity: 0.3;
  }

  @media (max-width: 650px) {
    display: none;
  }
`
