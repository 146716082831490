import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import TwoImages from './two-images'
import ImageCarousel from './image-carousel'
import MiddleImages from './middle-images'
import FullWidthImage from './full-width-image'

export const fragment = graphql`
  fragment ProjectMainContentFragment on WordPress_Project_Projectfields {
    mainContent {
      firstImages {
        imageLeft {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageRight {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      middleImages {
        imageLeft {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageRight {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      imageCarousel {
        sourceUrl
        mediaItemId
        modified
        altText
        caption
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      fullWidthImage {
        text
        image {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      finalImages {
        imageLeft {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageRight {
          sourceUrl
          mediaItemId
          modified
          altText
          caption
          imageFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const MainContent = ({ firstImages, imageCarousel, middleImages, fullWidthImage, finalImages }) => (
  <Wrapper>
    {firstImages.imageLeft && firstImages.imageRight && <TwoImages {...firstImages} />}
    <ImageCarousel imageCarousel={imageCarousel} />
    <MiddleImages {...middleImages} />
    <FullWidthImage {...fullWidthImage} />
    {finalImages.imageLeft && finalImages.imageRight && <TwoImages {...finalImages} />}
  </Wrapper>
)

export default MainContent

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 8vw;
`
